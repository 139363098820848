import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { setShowingDialogState } from 'src/redux/slices/showDialog.slice';
import { RootState } from 'src/redux/store';
import { fpost } from '../../../lib/fetch';
import { configDouble, dataDouble } from './DoubleChart';
import { config as configFlowRate, data as dataFlowRate } from './FlowRateConfig';
import './WaterLevel.css';
import { configWater, dataWater } from './WaterLevelConfig';
import { Button } from '../../../lib/components/Button.component';
import { ResponseListDocuments } from '../../dashboard/dto/response';
import { DeviceRecord } from '../../dashboard/dto/device/deviceRecord';
import { toasting } from '../../../lib/components/Toast.component';
// @ts-ignore
import DatePicker from 'react-datepicker';
// @ts-ignore
import { CSVLink } from 'react-csv';
import { configVelocity, dataVelocity } from './VelocityConfig';
import { AiOutlineReload } from 'react-icons/ai';
import { twMerge } from 'tailwind-merge';
import { format } from 'date-fns';
import debounce from "lodash.debounce";
import {option} from "../../dashboard/interfaces/option";

export function DialogWaterLevel() {
  const showDialog = useSelector((state: RootState) => state.showDialogReducer.showDialogWaterLevel);
  const dataDialog = useSelector((state: RootState) => state.showDialogReducer.position);
  const isMap = useSelector((state: RootState) => state.showDialogReducer.isMap);
  const isWaterLevel = useSelector((state: RootState) => state.showDialogReducer.isWaterLevel);
  const isFlowRate = useSelector((state: RootState) => state.showDialogReducer.isFlowRate);

  let date, firstDayCsv, lastDayCsv;
  date = new Date();
  firstDayCsv = new Date(date.getFullYear(), date.getMonth(), 1);
  lastDayCsv = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const csvHeaders = ['SID', 'CNT', 'RF', 'VOL', 'DIS', 'PUBLISH', 'WATER_LEVEL', 'VELOCITY', 'FLOW_RATE'];
  const [csvData, setCsvData] = useState<any[]>([]);
  const [downloadCsvProcessing, setDownloadCsvProcessing] = useState(false);
  const [active, setActive] = useState(1);
  const [disabledSwitchButton, setDisabledSwitchButton] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isReloading, setIsReloading] = useState(false);
  const csvLinkRef = useRef<any>(null);
  const [isRangeInvalid, setIsRangeInvalid] = useState(false);
  const [isTimeSelectable, setIsTimeSelectable] = useState(false);
  const [isMonthSelectable, setIsMonthSelectable] = useState(false);
  const [isYearSelectable, setIsYearSelectable] = useState(false);
  const [dateFormat, setDateformat] = useState('yyyy-MM-dd');
  const [minVelocitySetting, setMinVelocitySetting] = useState<number | null>(null);
  const [maxVelocitySetting, setMaxVelocitySetting] = useState<number | null>(null);
  const [minWaterRateSetting, setMinWaterRateSetting] = useState<number | null>(null);
  const [maxWaterRateSetting, setMaxWaterRateSetting] = useState<number | null>(null);
  const [minWaterLevelSetting, setMinWaterLevelSetting] = useState<number | null>(null);
  const [maxWaterLevelSetting, setMaxWaterLevelSetting] = useState<number | null>(null);

  const handleMinMax = async (e:any, inputCase:string) => {

    const newValue = e.target.value;
    switch (inputCase) {
      case 'minVelocitySetting':
        setMinVelocitySetting(newValue);
        break;
      case 'maxVelocitySetting':
        setMaxVelocitySetting(newValue);
        break;
      case 'minWaterRateSetting':
        setMinWaterRateSetting(newValue);
        break;
      case 'maxWaterRateSetting':
        setMaxWaterRateSetting(newValue);
        break;
      case 'minWaterLevelSetting':
        setMinWaterLevelSetting(newValue);
        break;
      case 'maxWaterLevelSetting':
        setMaxWaterLevelSetting(newValue);
        break;
    }
    // Gọi hàm debounce
    // await debouncedChange(active);
  };

  const option:option = {
    minVelocitySetting: minVelocitySetting,
    maxVelocitySetting: maxVelocitySetting,
    minWaterRateSetting: minWaterRateSetting,
    maxWaterRateSetting: maxWaterRateSetting,
    minWaterLevelSetting: minWaterLevelSetting,
    maxWaterLevelSetting: maxWaterLevelSetting,
  }

  const [dateRange, setDateRange] = useState([firstDayCsv, lastDayCsv]);
  const [startDateCsv, endDateCsv] = dateRange;

  const getHourInDay = (hour: number) => {
    const newHour = (hour + 9) % 24;
    return newHour >= 0 ? newHour : newHour + 24;
  }

  const getFirstDayOfWeek = (date:Date) => {
    return date.getTime() - ((date.getDay() === 0 ? 6 : date.getDay() - 1)) * 24 * 60 * 60 * 1000
  }

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const getTimeWithZero = (time: number) => {
    return time < 10 ? '0' + time : time;
  }

  const setChartLabelString = (action: number) => {
    let label = '';

    let year = new Date().getFullYear();
    let month: number | string = new Date().getMonth() + 1;
    let hour = new Date().getHours();
    let day = new Date().getDate();
    let dow = new Date().getDay() - 1;
    let startDayOfWeekTimestamp = getFirstDayOfWeek(new Date());
    if (startDate){
      year = startDate.getFullYear();
      month = startDate.getMonth() + 1;
      hour = startDate.getHours();
      day = startDate.getDate();
      dow = startDate.getDay() - 1;
      startDayOfWeekTimestamp = getFirstDayOfWeek(startDate);
    }
    // convert to two digits
    month = month < 10 ? '0' + month.toString() : month;

    const endDayOfWeekTimestamp = startDayOfWeekTimestamp + 6 * 24 * 60 * 60 * 1000;
    const endDayOfWeek = new Date(endDayOfWeekTimestamp);
    const startDayOfWeek = new Date(startDayOfWeekTimestamp);
    const startDayOfWeekFull = `${startDayOfWeek.getFullYear()}/${getTimeWithZero(startDayOfWeek.getMonth() + 1)}/${getTimeWithZero(startDayOfWeek.getDate())}`
    const endDayOfWeekFull = `${endDayOfWeek.getFullYear()}/${getTimeWithZero(endDayOfWeek.getMonth() + 1)}/${getTimeWithZero(endDayOfWeek.getDate())}週`
    switch (action) {
      case 1:
        label = `${year}年`;
        break;
      case 2:
        label = `${year}/${month}月`;
        break;
      case 3:
        label = `
         ${startDayOfWeekFull} - ${endDayOfWeekFull}
        `;
        break;
      case 4:
        label = `${year}/${month}/${day}日`;
        break;
      case 5:
        label = `${year}/${month}/${day} ${hour}:00時`;
        break;
    }

    configDouble.options.scales.xAxes[0].scaleLabel.labelString = label;
    configWater.options.scales.xAxes[0].scaleLabel.labelString = label;
    configFlowRate.options.scales.xAxes[0].scaleLabel.labelString = label;
    configVelocity.options.scales.xAxes[0].scaleLabel.labelString = label;
  };

  const getAdjustedValue = (min:number, max:number) => {
    const range = max - min;
    const scalingFactor = 0.4;

    const adjustedMin = min - range * scalingFactor;
    const adjustedMax = max + range * scalingFactor;

    return [
      adjustedMin,
      adjustedMax,
    ];
  }

  const isSameDay = (startDay: Date, endDay: Date) => {
    return (
      startDay.getDate() === endDay.getDate() &&
      startDay.getMonth() === endDay.getMonth() &&
      startDay.getFullYear() === endDay.getFullYear()
    );
  };

  const isSameMonth = (startDay: Date, endDay: Date) => {
    return (
      startDay.getMonth() === endDay.getMonth() &&
      startDay.getFullYear() === endDay.getFullYear()
    );
  };

  const isSameYear = (startDay: Date, endDay: Date) => {
    return startDay.getFullYear() === endDay.getFullYear();
  };

  const isWithinSameWeek = (startDay: Date, endDay: Date) => {
    // Create a copy of startDay for calculations
    const startOfWeek = new Date(startDay);


    // Adjust startOfWeek to the first day of the week (Monday)
    const dayOfWeek = startDay.getDay(); // Get the day of the week (Sunday is 0, Monday is 1, ...)

    // Adjust to Monday (start of the week)
    const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
    startOfWeek.setDate(startDay.getDate() + diffToMonday); // Set startOfWeek to Monday

    // Set endOfWeek to Sunday using setDate
    const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(),startOfWeek.getDate());
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday is 6 days after Monday

    // Check if endDay is within the range from startOfWeek to endOfWeek
    return endDay >= startOfWeek && endDay <= endOfWeek;
  };

  const isSameHour = (startDay: Date, endDay: Date) => {
    return (
      startDay.getFullYear() === endDay.getFullYear() &&
      startDay.getMonth() === endDay.getMonth() &&
      startDay.getDate() === endDay.getDate() &&
      startDay.getHours() === endDay.getHours()
    );
  }


  const resetGraphData = (quantity:number) => {
    dataDouble.datasets[0].data = Array.from({ length: quantity }, () => null);
    dataDouble.datasets[1].data = Array.from({ length: quantity }, () => null);
    dataDouble.datasets[2].data = Array.from({ length: quantity }, () => null);
    dataFlowRate.datasets[0].data = Array.from({ length: quantity }, () => null);
    dataWater.datasets[0].data = Array.from({ length: quantity }, () => null);
    dataVelocity.datasets[0].data = Array.from({ length: quantity }, () => null);
  }

  const getYear = async (startDate:Date|null) => {
    setIsYearSelectable(true);
    setIsTimeSelectable(false)
    setIsMonthSelectable(false)
    setDateformat('yyyy')
    // validate
    const deviceId = dataDialog?.deviceId;
    if (!deviceId) return;

    // set label
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    dataDouble.labels = labels;
    dataFlowRate.labels = labels;
    dataWater.labels = labels;
    dataVelocity.labels = labels;

    // set start, end date
    const now = new Date();
    const start = startDate ?
      new Date(startDate.getFullYear(), 0, 1) :
      new Date(now.getFullYear(), 0, 1);
    const end = new Date(start.getFullYear(), 11, 31) ;

    if (!isSameYear(start, end)) {
      resetGraphData(12)
      return
    }

    setDisabledSwitchButton(true);

    // get record
    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: start.getTime().toString() },
          ':timeEnd': { S: end.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_YEAR',
        ScanIndexForward: false,
      },
    };
    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });

    const items = data[1].Items.reverse();
    resetGraphData(12)

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;
    for (const item of items) {
      const month = new Date(parseInt(item.CREATE_AT.S)).getMonth();
      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[month] = waterLevel;
        dataWater.datasets[0].data[month] = waterLevel;

        maxWaterLevel = Math.max(maxWaterLevel, waterLevel);
        minWaterLevel = Math.min(waterLevel, minWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[month] = flowRate;
        dataFlowRate.datasets[0].data[month] = flowRate;

        maxFlowRate = Math.max(maxFlowRate, flowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }
      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[month] = velocity;
        dataVelocity.datasets[0].data[month] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    if (option.minWaterRateSetting && option.maxWaterRateSetting) {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(option.minWaterRateSetting);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(option.maxWaterRateSetting);
    } else {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);
    }

    if (option.maxWaterLevelSetting && option.minWaterLevelSetting) {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(option.minWaterLevelSetting);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(option.maxWaterLevelSetting);
    } else {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);
    }

    if(option.minVelocitySetting && option.maxVelocitySetting) {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(option.minVelocitySetting);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(option.maxVelocitySetting);
    } else {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);
    }

    setDisabledSwitchButton(false);
  };

  const getMonth = async (startDate:Date|null) => {
    console.log("get month");
    setIsYearSelectable(false);
    setIsTimeSelectable(false)
    setIsMonthSelectable(true)
    setDateformat('yyyy-MM')

    // validate
    const deviceId = dataDialog?.deviceId;
    if (!deviceId) return;

    // set start end date
    const now = new Date();
    const startDay = startDate ? new Date(startDate.getFullYear(), startDate.getMonth(), 1) : new Date(now.getFullYear(), now.getMonth(), 1);
    const endDay = new Date(startDay.getFullYear(), startDay.getMonth() + 1, 0);
    endDay.setHours(23, 59, 59)

    // set graph label
    const daysInMonth = endDay.getDate();
    const labels = Array.from({ length: daysInMonth }, (_, index) => (index + 1).toString());
    dataDouble.labels = labels;
    dataFlowRate.labels = labels;
    dataWater.labels = labels;

    if (!isSameMonth(startDay, endDay)) {
      resetGraphData(daysInMonth);
      return
    }

    setDisabledSwitchButton(true);

    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: startDay.getTime().toString() },
          ':timeEnd': { S: endDay.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_MONTH',
        ScanIndexForward: false,
      },
    };
    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });
    const items = data[1].Items.reverse();

    resetGraphData(daysInMonth);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const dom = new Date(parseInt(item.CREATE_AT.S)).getDate();

      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[dom - 1] = waterLevel;
        dataWater.datasets[0].data[dom - 1] = waterLevel;

        maxWaterLevel = Math.max(maxWaterLevel, waterLevel);
        minWaterLevel = Math.min(minWaterLevel, waterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[dom - 1] = flowRate;
        dataFlowRate.datasets[0].data[dom - 1] = flowRate;

        maxFlowRate = Math.max(maxFlowRate, flowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }
      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[dom - 1] = velocity;
        dataVelocity.datasets[0].data[dom - 1] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    if (option.minWaterRateSetting && option.maxWaterRateSetting) {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(option.minWaterRateSetting);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(option.maxWaterRateSetting);
    } else {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);
    }

    if (option.maxWaterLevelSetting && option.minWaterLevelSetting) {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(option.minWaterLevelSetting);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(option.maxWaterLevelSetting);
    } else {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);
    }

    if(option.minVelocitySetting && option.maxVelocitySetting) {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(option.minVelocitySetting);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(option.maxVelocitySetting);
    } else {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);
    }

    setDisabledSwitchButton(false);
  };

  const getWeek = async (startDate:Date|null) => {
    setIsYearSelectable(false);
    setIsTimeSelectable(false)
    setIsMonthSelectable(false)
    setDateformat('yyyy-MM-dd')

    // validate
    if (isRangeInvalid) return
    const deviceId = dataDialog?.deviceId;
    if (!deviceId) return;

    // set graph label
    const labels = ['MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT', 'SUN'];
    dataDouble.labels = labels;
    dataFlowRate.labels = labels;
    dataWater.labels = labels;

    // set start, end date
    const startDay = startDate ? new Date(startDate.getTime()) : new Date();
    startDay.setHours(0, 0, 0, 0);

    setDisabledSwitchButton(true);

    const firstDayOfWeek = getFirstDayOfWeek(startDay);
    const lastDayOfWeek = new Date(firstDayOfWeek + 6 * 24 * 60 * 60 * 1000);
    lastDayOfWeek.setHours(23, 59, 59);

    // get records
    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: firstDayOfWeek.toString() },
          ':timeEnd': { S: lastDayOfWeek.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_MONTH',
        ScanIndexForward: false,
      },
    };

    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });
    const items = data[1].Items.reverse();

    resetGraphData(7);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const dow = new Date(parseInt(item.CREATE_AT.S)).getDay() == 0 ? 6 : new Date(parseInt(item.CREATE_AT.S)).getDay() - 1;

      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[dow] = waterLevel;
        dataWater.datasets[0].data[dow] = waterLevel;

        maxWaterLevel = Math.max(maxWaterLevel, waterLevel);
        minWaterLevel = Math.min(waterLevel, minWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[dow] = flowRate;
        dataFlowRate.datasets[0].data[dow] = flowRate;

        maxFlowRate = Math.max(maxFlowRate, flowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }

      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[dow] = velocity;
        dataVelocity.datasets[0].data[dow] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }

    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    if (option.minWaterRateSetting && option.maxWaterRateSetting) {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(option.minWaterRateSetting);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(option.maxWaterRateSetting);
    } else {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);
    }

    if (option.maxWaterLevelSetting && option.minWaterLevelSetting) {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(option.minWaterLevelSetting);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(option.maxWaterLevelSetting);
    } else {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);
    }

    if(option.minVelocitySetting && option.maxVelocitySetting) {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(option.minVelocitySetting);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(option.maxVelocitySetting);
    } else {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);
    }

    setDisabledSwitchButton(false);
  };

  const getDay = async (startDate:Date|null) => {
    setIsYearSelectable(false);
    setIsTimeSelectable(false)
    setIsMonthSelectable(false)
    setDateformat('yyyy-MM-dd')

    // validate
    const deviceId = dataDialog?.deviceId;
    if (!deviceId) return;

    // label
    const labels = Array.from({ length: 24 }, (_, index) => ((index)).toString());
    dataDouble.labels = labels;
    dataFlowRate.labels = labels;
    dataWater.labels = labels;

    // set start, end day
    const startDay = startDate ? new Date(startDate?.getTime()) : new Date();
    const endDay = new Date(startDay.getTime());
    startDay.setHours(0, 0, 0, 0);
    endDay.setHours(23, 59, 59);

    if(!isSameDay(startDay, endDay)) {
      resetGraphData(24);
      return;
    }

    setDisabledSwitchButton(true);

    // get records
    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: startDay.getTime().toString() },
          ':timeEnd': { S: endDay.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_DAY',
        ScanIndexForward: false,
      },
    };
    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });

    const items = data[1].Items.reverse();
    resetGraphData(24);
    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;
    for (const item of items) {
      const hour = new Date(parseInt(item.CREATE_AT.S)).getUTCHours();
      // if (new Date(parseInt(item.CREATE_AT.S)).getUTCDay() !== new Date().getUTCDay()) continue;
      const idx = getHourInDay(hour); // 1 for index, 1 for hours
      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[idx] = waterLevel;
        dataWater.datasets[0].data[idx] = waterLevel;

        maxWaterLevel = Math.max(maxWaterLevel, waterLevel);
        minWaterLevel = Math.min(waterLevel, minWaterLevel);
      }
      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[idx] = flowRate;
        dataFlowRate.datasets[0].data[idx] = flowRate;

        maxFlowRate = Math.max(maxFlowRate, flowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }

      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[idx] = velocity;
        dataVelocity.datasets[0].data[idx] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }
    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    if (option.minWaterRateSetting && option.maxWaterRateSetting) {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(option.minWaterRateSetting);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(option.maxWaterRateSetting);
    } else {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);
    }

    if (option.maxWaterLevelSetting && option.minWaterLevelSetting) {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(option.minWaterLevelSetting);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(option.maxWaterLevelSetting);
    } else {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);
    }

    if(option.minVelocitySetting && option.maxVelocitySetting) {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(option.minVelocitySetting);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(option.maxVelocitySetting);
    } else {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);
    }

    setDisabledSwitchButton(false);
  };

  const getHour = async (startDate:Date|null) => {
    setIsYearSelectable(false);
    setIsTimeSelectable(true)
    setIsMonthSelectable(false)
    setDateformat('yyyy-MM-dd HH:mm')
    // validate
    const deviceId = dataDialog?.deviceId;
    if (!deviceId) return;

    // set label
    const labels = Array.from({ length: 12 }, (_, index) => ((index/12) *60).toString());
    dataDouble.labels = labels;
    dataFlowRate.labels = labels;
    dataWater.labels = labels;

    // set start, end day
    const startDay = startDate ? startDate : new Date();
    const endDay = new Date(startDay.getTime());
    startDay.setMinutes(0, 0)
    endDay.setMinutes(59, 59)

    if(!isSameHour(startDay, endDay)) {
      resetGraphData(12);
      return
    }

    setDisabledSwitchButton(true);

    // get records
    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeEnd': { S: endDay.getTime().toString() },
          ':timeStart': { S: startDay.getTime().toString() },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and CREATE_AT BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD_HOUR',
        ScanIndexForward: false,
      },
    };

    const data = await fpost<any>('api/device/record/query', {
      body: query,
    });
    const items = data[1].Items.reverse();

    resetGraphData(12);

    let maxWaterLevel = 100;
    let maxFlowRate = 10;
    let maxVelocity = 10;
    let minWaterLevel = 0;
    let minFlowRate = 0;
    let minVelocity = 0;

    for (const item of items) {
      const minute = new Date(parseInt(item.CREATE_AT.S)).getUTCMinutes();
      const idx = (minute/60) * 12;
      if (item.WATER_LEVEL) {
        const waterLevel = item.WATER_LEVEL.S;
        dataDouble.datasets[1].data[idx] = waterLevel;
        dataWater.datasets[0].data[idx] = waterLevel;

        maxWaterLevel = Math.max(maxWaterLevel, waterLevel);
        minWaterLevel = Math.min(waterLevel, minWaterLevel);
      }

      if (item.FLOW_RATE) {
        const flowRate = item.FLOW_RATE.S;
        dataDouble.datasets[0].data[idx] = flowRate;
        dataFlowRate.datasets[0].data[idx] = flowRate;

        maxFlowRate = Math.max(maxFlowRate, flowRate);
        minFlowRate = Math.min(flowRate, minFlowRate);
      }

      if (item.VELOCITY) {
        const velocity = item.VELOCITY.S;
        dataDouble.datasets[2].data[idx] = velocity;
        dataVelocity.datasets[0].data[idx] = velocity;

        maxVelocity = Math.max(maxVelocity, velocity);
        minVelocity = Math.min(minVelocity, velocity);
      }
    }
    const [adjustedMinWaterLevel, adjustedMaxWaterLevel] = getAdjustedValue(minWaterLevel, maxWaterLevel);
    const [adjustedMinFlowRate, adjustedMaxFlowRate] = getAdjustedValue(minFlowRate, maxFlowRate);
    const [adjustedMinVelocity, adjustedMaxVelocity] = getAdjustedValue(minVelocity, maxVelocity);

    if (option.minWaterRateSetting && option.maxWaterRateSetting) {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(option.minWaterRateSetting);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(option.maxWaterRateSetting);
    } else {
      configDouble.options.scales.yAxes[0].ticks.min = Math.floor(adjustedMinFlowRate);
      configDouble.options.scales.yAxes[0].ticks.max = Math.ceil(adjustedMaxFlowRate);
    }

    if (option.maxWaterLevelSetting && option.minWaterLevelSetting) {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(option.minWaterLevelSetting);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(option.maxWaterLevelSetting);
    } else {
      configDouble.options.scales.yAxes[1].ticks.min = Math.floor(adjustedMinWaterLevel);
      configDouble.options.scales.yAxes[1].ticks.max = Math.ceil(adjustedMaxWaterLevel);
    }

    if(option.minVelocitySetting && option.maxVelocitySetting) {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(option.minVelocitySetting);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(option.maxVelocitySetting);
    } else {
      configDouble.options.scales.yAxes[2].ticks.min = Math.floor(adjustedMinVelocity);
      configDouble.options.scales.yAxes[2].ticks.max = Math.ceil(adjustedMaxVelocity);
    }

    setDisabledSwitchButton(false);
  }

  const getRecordCsv = async () => {
    if(isRangeInvalid) {
      return
    }
    setDownloadCsvProcessing(true);
    if (startDateCsv && endDateCsv) {
      firstDayCsv = new Date(startDateCsv);
      endDateCsv.setHours(23, 59, 59);
      lastDayCsv = new Date(endDateCsv);
    } else {
      date = new Date();
      firstDayCsv = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDayCsv = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
    }
    const deviceId = dataDialog.deviceId;
    if (!deviceId) {
      return;
    }
    const query = {
      query: {
        ExpressionAttributeValues: {
          ':id': { S: deviceId },
          ':timeStart': { S: format(firstDayCsv, 'yyyy/M/d HH:mm:ss') },
          ':timeEnd': { S: format(lastDayCsv, 'yyyy/M/d HH:mm:ss') },
        },
        KeyConditionExpression: 'DEVICE_ID = :id and Publish BETWEEN :timeStart and :timeEnd',
        TableName: 'DEVICE_RECORD',
        Select:"ALL_ATTRIBUTES",
        IndexName: 'DEVICE_ID-Publish-index',
        ReturnConsumedCapacity: "TOTAL",
        ScanIndexForward: false,
      },
    };

    const data = await fpost<any, ResponseListDocuments<DeviceRecord>>('api/device/record/query', {
      body: query,
    });

    const items = data[1].Items;
    const records: any[] = [];
    items?.forEach((it: any) => {
      records.push([
        it.SID?.S,
        it.CNT?.S,
        it.RF?.S,
        it.VOL?.S,
        it.DIS?.S,
        it.Publish?.S,
        it.WATER_LEVEL?.S,
        it.VELOCITY?.S,
        it.FLOW_RATE?.S,
      ]);
    });
    setCsvData(records);
    setDownloadCsvProcessing(false);
    if (!records.length) {
      toasting({ children: 'データがありません', containerProps: { className: 'border-blue-600' } });
    }
  };

  const handleReload = async (active: number) => {
    setIsReloading(true);
    setChartLabelString(active)
    switch (active) {
      case 1:
        await getYear(startDate);
        break;
      case 2:
        await getMonth(startDate);
        break;
      case 3:
        await getWeek(startDate);
        break;
      case 4:
        await getDay(startDate);
        break;
      case 5:
        await getHour(startDate);
        break;
      default:
        break;
    }
    setIsReloading(false);
  };

  useEffect( () => {
    const reload = async () => {
      switch (active) {
        case 1:
          await getYear(startDate);
          break;
        case 2:
          await getMonth(startDate);
          break;
        case 3:
          await getWeek(startDate);
          break;
        case 4:
          await getDay(startDate);
          break;
        case 5:
          await getHour(startDate);
          break;
        default:
          break;
      }
    }
    setChartLabelString(active)
    reload()

  }, [
    startDate,
    minVelocitySetting,
    maxVelocitySetting,
    minWaterRateSetting,
    maxWaterRateSetting,
    minWaterLevelSetting,
    maxWaterLevelSetting,
  ])

  useEffect(() => {
    if (csvData.length > 0) {
      console.log('start download');
      console.log(csvLinkRef.current.link);
      csvLinkRef.current.link.click();
    }
  }, [csvData]);

  useEffect(() => {
    if (!showDialog) return;
    const start = async () => {
      setActive(4);
      setChartLabelString(4);
      await getDay(startDate);
    };
    start();
  }, [showDialog]);

  const dispatch = useDispatch();

  return showDialog ? (
    <div
      className="absolute top-0 h-full w-full text-center"
      style={{ background: 'rgba(0,0,0,0.5)', zIndex: 1000 }}
      onMouseMove={(e) => {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      }}
      onClick={(e) => {
        if (csvLinkRef.current) {
          // Nếu click vào link, không ngăn chặn
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      }}>
      <div
        id="DialogWaterLevel"
        className="relative m-auto w-full max-w-3xl max-md:h-[100vh] top-0 flex items-center"
        >
        {/* <!-- Modal content --> */}
        <div className="relative rounded-lg bg-white shadow dark:bg-gray-700 w-full">
          {/* <!-- Modal header --> */}
          <p className="font-bold p-2" >{dataDialog.pos}</p>
          <div className="flex flex-wrap items-center justify-between rounded-t border-b p-4 dark:border-gray-600 ">
            <div className="inline  border-2 " style={{borderColor: '#015CAC'}}>
              <button
                onClick={() => {
                  setActive(1);
                  setChartLabelString(1);
                  getYear(startDate);
                }}
                className={(active == 1 ? 'btn-active' : 'btn') + ' inline px-3 text-center'}
                disabled={disabledSwitchButton}>
                年
              </button>
              <button
                onClick={() => {
                  setActive(2);
                  setChartLabelString(2);
                  getMonth(startDate);
                }}
                className={(active == 2 ? 'btn-active' : 'btn') + ' inline px-3 text-center'}
                disabled={disabledSwitchButton}>
                月
              </button>
              <button
                onClick={() => {
                  setActive(3);
                  setChartLabelString(3);
                  getWeek(startDate);
                }}
                className={(active == 3 ? 'btn-active' : 'btn') + ' inline px-3 text-center'}
                disabled={disabledSwitchButton}>
                週
              </button>
              <button
                onClick={() => {
                  setActive(4);
                  setChartLabelString(4);
                  getDay(startDate);
                }}
                className={(active == 4 ? 'btn-active' : 'btn') + ' inline px-3 text-center'}
                disabled={disabledSwitchButton}>
                日
              </button>
              <button
                onClick={() => {
                  setActive(5);
                  setChartLabelString(5);
                  getHour(startDate);
                }}
                className={(active == 5 ? 'btn-active' : 'btn') + ' inline px-3 text-center'}
                disabled={disabledSwitchButton}>
                時
              </button>
            </div>
            <DatePicker
              className={`rounded-lg ${isRangeInvalid ? 'border-red-500' : 'border-inherit'} md:ml-5 max-md:!mt-5 mt-0 start-date`}
              selected={startDate}
              onChange={(update:any) => {
                handleStartDateChange(update)
              }}
              showTimeSelect={isTimeSelectable}
              showMonthYearPicker={isMonthSelectable}
              showYearPicker={isYearSelectable}
              isClearable={true}
              timeFormat="HH:mm"
              timeIntervals={60}
              dateFormat={dateFormat}
              placeholderText="Select start date"
              showWeekNumbers

            />
            <AiOutlineReload
              className={twMerge(
                'ml-5 cursor-pointer text-[#015CAC] hover:scale-110',
                isReloading ? 'animate-spin' : 'animate-none',
              )}
              onClick={() => {
                handleReload(active);
              }}
              strokeWidth={10}
              title="リセット"
              size={24}
            />
            <button
              type="button"
              className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              // data-modal-toggle="defaultModal"
              onClick={() => {
                dispatch(setShowingDialogState({showDialogWaterLevel: false, isMap: true}));
              }}>
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <div className=" p-6 border-b dark:border-gray-600">
            {/*flow rate*/}
            <div className="flex items-center space-x-4">
              <label htmlFor="minFlow" className="font-medium text-sm whitespace-nowrap">流量</label>
              <div className="flex items-center space-x-2">
                <input
                    type="number"
                    id="minFlow"
                    placeholder="Min"
                    className="rounded border p-1 w-20 text-sm"
                    value={minWaterRateSetting ?? ''}
                    onChange={(e) => handleMinMax(e, 'minWaterRateSetting')}
                />
                <span className="text-gray-500">-</span>
                <input
                    type="number"
                    id="maxFlow"
                    placeholder="Max"
                    className="rounded border p-1 w-20 text-sm"
                    value={maxWaterRateSetting ?? ''}
                    onChange={(e) => handleMinMax(e, 'maxWaterRateSetting')}
                />
              </div>
            </div>
            {/*water level*/}
            <div className="flex items-center space-x-4 mt-2">
              <label htmlFor="minFlow" className="font-medium text-sm whitespace-nowrap">水位</label>
              <div className="flex items-center space-x-2">
                <input
                    type="number"
                    id="minFlow"
                    placeholder="Min"
                    className="rounded border p-1 w-20 text-sm"
                    value={minWaterLevelSetting ?? ''}
                    onChange={(e) => handleMinMax(e, 'minWaterLevelSetting')}
                />
                <span className="text-gray-500">-</span>
                <input
                    type="number"
                    id="maxFlow"
                    placeholder="Max"
                    className="rounded border p-1 w-20 text-sm"
                    value={maxWaterLevelSetting ?? ''}
                    onChange={(e) => handleMinMax(e, 'maxWaterLevelSetting')}
                />
              </div>
            </div>
            {/* velocity */}
            <div className="flex items-center space-x-4 mt-2 mb-4">
              <label htmlFor="minFlow" className="font-medium text-sm whitespace-nowrap">流速</label>
              <div className="flex items-center space-x-2">
                <input
                    type="number"
                    id="minFlow"
                    placeholder="Min"
                    className="rounded border p-1 w-20 text-sm"
                    value={minVelocitySetting ?? ''}
                    onChange={(e) => handleMinMax(e, 'minVelocitySetting')}
                />
                <span className="text-gray-500">-</span>
                <input
                    type="number"
                    id="maxFlow"
                    placeholder="Max"
                    className="rounded border p-1 w-20 text-sm"
                    value={maxVelocitySetting ?? ''}
                    onChange={(e) => handleMinMax(e, 'maxVelocitySetting')}
                />
              </div>
            </div>
            {/* {isWaterLevel && !isFlowRate ? <Line data={dataWater} options={configWater.options} /> : ''}
            {isFlowRate && !isWaterLevel ? <Line data={dataFlowRate} options={configFlowRate.options} /> : ''}
            {(isFlowRate && isWaterLevel) || !isMap ? <Line data={dataDouble} options={configDouble.options} /> : ''} */}
            {<Line redraw={true} data={dataDouble} options={configDouble.options}/>}
          </div>

          <div className="block items-center justify-between rounded-t border-b p-4 dark:border-gray-600 ">
            <h3 className="text-left font-bold">CSV download</h3>
            <div className="flex align-middle">
              <div className="range-datetime-div flex">
                <DatePicker
                    selectsRange={true}
                    className={`rounded-lg ${isRangeInvalid ? 'border-red-500' : 'border-inherit'} mt-0 start-date`}
                    startDate={startDateCsv}
                    endDate={endDateCsv}
                    onChange={(update: any) => {
                      setDateRange(update);
                    }}
                    showTimeSelect={true}
                    isClearable={true}
                />
              </div>
              <div className="ml-5 mt-2.5 ">
                <Button
                    className="inline bg-black text-white"
                    onClick={getRecordCsv}
                    isProcessing={downloadCsvProcessing}>
                  CSVダウンロード
                </Button>
                <CSVLink
                    ref={csvLinkRef}
                    asyncOnClick={true}
                    className=""
                    data={csvData}
                    headers={csvHeaders}
                    filename="Raw_data.csv"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
